
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    list: Array
  },
  data() {
    return { 
      tab: this.list[0]
    }
  },
  methods: {

  }
  
})
export default class Menu extends Vue {
  msg!: string
}
