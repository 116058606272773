
import { Options, Vue } from 'vue-class-component';
import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src
import Tag from '@/components/Tag.vue'; // @ is an alias to /src
import Search from '@/components/Search.vue'; // @ is an alias to /src
import Step from '@/components/Step.vue'; // @ is an alias to /src
import Tips from '@/components/Tips.vue'; // @ is an alias to /src
import Recommend from '@/components/Recommend.vue'; // @ is an alias to /src
import Button from '@/components/Button.vue'; // @ is an alias to /src
import Comments from '@/components/Comments.vue'; // @ is an alias to /src
// 编译后代码
// import { Button } from 'vant';
// import 'vant/es/button/style';
// import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

// import { Swiper } from 'swiper';
// Import Swiper styles
// import 'swiper/css';


@Options({
  components: {
    HelloWorld,
    Tag,
    Search,
    Step,
    Tips,
    Recommend,
    Button,
    Comments
  },
  data() {
    return { 
      info: {
        name: "Lilith Wong"
      },
      list: ["Today’s","Healthy", "Bakery", "Specialty", "Nutrition"],
      popular: [
        {
          "image": require("@/assets/step1.png"),
          "name": "Roast Steak",
          "title": "Beat the eggs into the flour",
          "desc": "Beat the eggs into the flour beat the eggs into the flour beat the eggs into the flour beat the…"
        },
        {
          "image": require("@/assets/step2.png"),
          "name": "Salad",
          "title": "Add water and mix",
          "desc": "Beat the eggs into the flour beat the…"
        },
      ]
    }
  }
})
export default class Detail extends Vue {

}
