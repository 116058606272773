import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2e15b3bc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tag" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item) => {
        return (_openBlock(), _createElementBlock("li", {
          key: item,
          class: _normalizeClass([
              _ctx.tab == item 
                ? 'menu_select'
                : '',
            ]),
          onClick: ($event: any) => (_ctx.clickMenu(item))
        }, _toDisplayString(item), 11, _hoisted_2))
      }), 128))
    ])
  ]))
}