
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    txt: String
  },
  methods: {

  }
  
})
export default class Search extends Vue {
  msg!: string
}
