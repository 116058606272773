import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-882ec320"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "step" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "step_cont" }
const _hoisted_4 = { class: "step_cont-desc" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (i, key) => {
        return (_openBlock(), _createElementBlock("li", { key: i }, [
          _createElementVNode("div", null, _toDisplayString(key + 1) + "/" + _toDisplayString(_ctx.list.length) + _toDisplayString(i.title), 1),
          _createElementVNode("img", {
            src: i.image
          }, null, 8, _hoisted_2),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(i.desc), 1)
          ])
        ]))
      }), 128))
    ])
  ]))
}