import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-00924f01"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tips" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (i) => {
        return (_openBlock(), _createElementBlock("li", { key: i }, [
          _createElementVNode("img", {
            src: i.image
          }, null, 8, _hoisted_2),
          _createElementVNode("p", null, _toDisplayString(i.name), 1)
        ]))
      }), 128))
    ])
  ]))
}